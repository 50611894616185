<template>
  <div class="content">
    <div class="size_title">居间服务协议书</div>
    <div v-if="queryList.id">
      <div class="size_weight">甲方(出让人):<span>{{dataList.sales_user.union_info.username}}</span></div>
      <div>居民身份证号码：<span>{{dataList.sales_user.union_info.identity}}</span></div>
      <div>收件地址：<span>{{dataList.sales_user.union_info.address}}</span></div>
      <div>联系电话：<span>{{dataList.sales_user.union_info.phone}}</span></div>
    </div>
    <div v-else>
      <div class="size_weight">甲方(出让人):<span>{{tableData.user.union_info.username}}</span></div>
      <div>居民身份证号码：<span>{{tableData.user.union_info.identity}}</span></div>
      <div>收件地址：<span>{{tableData.user.union_info.address}}</span></div>
      <div>联系电话：<span>{{tableData.user.union_info.phone}}</span></div>
    </div>
    <div v-if="queryList.id">
      <div class="size_weight">乙方(受让人):<span>{{tableData.user.union_info.username}}</span></div>
      <div>居民身份证号码：<span>{{tableData.user.union_info.identity}}</span></div>
      <div>收件地址：<span>{{tableData.user.union_info.address}}</span></div>
      <div>联系电话：<span>{{tableData.user.union_info.phone}}</span></div>
    </div>
    <div v-else>
      <div class="size_weight">乙方(受让人):<span></span></div>
      <div>居民身份证号码：<span></span></div>
      <div>收件地址：<span></span></div>
      <div>联系电话：<span></span></div>
    </div>

    <div class="size_weight">丙方（居间服务方）：<span>武汉鸿运荣生态科技有限公司</span></div>
    <div>法定代表⼈：<span>余露</span></div>
    <div>统一社会信用代码：<span>91420100MA4F3LXNXB</span></div>
    <div>住所地：<span>湖北省武汉东湖新技术开发区光谷大道58号1</span><span>栋H1032(自贸区武汉片区）。</span></div>
    <div>联系电话：<span>18372115504</span></div>
    <div class="size_weight">甲、乙、丙三方根据《中华人民共和国民法典》,经友好协商,依平等自愿、等价有偿的原则,达成如下协议：</div>
    <div class="size_weight">一、居间服务项目</div>
    <div>居间服务项目为湖北利道科技投资有限公司工会委员会免费赠予给甲方的分红权转让事宜(以下简称“分红权转让项目”)。</div>
    <div class="size_weight">二、委托事项</div>
    <div>1、丙方接受甲方委托,引荐乙方与甲方进行接触、沟通、协商、洽谈,在乙方完成尽职调查,确认有意受让分红权转让项目后,促成甲、乙双方订立分红权转让项目的转让合同。</div>
    <div>2、本协议所称居间成功,特指甲方与乙方在委托期限内就分红权转让项目的转让价款、付款时间和方式、双方权利义务、违约贡任等事宣达成一致并就此订立《居间服务协议书》及《利道工会会员分红权赠予合同书》。</div>
    <div>3、委托期限为 3 个月,自本协议订立之日起计算。如甲方未在委托期限内与乙方或湖北利道科技投资有限公司工会委员会就分红权转让项目订立《利道工会会员分红权赠予合同书》,则本协议自动终止。</div>
    <div v-if="queryList.id">4、甲方委托丙方将甲方从湖北利道科技投资有限公司工会委员会免费受赠的<span>{{dataList.sales.shares}}</span>权数的分红权以人民币<span>{{dataList.sales.shares_price}}</span>元/权数的分红权转让给乙方，乙方受让以上全部权数的分红权须向甲方共计支付人民币<span>{{dataList.sales.shares_amount}}</span> 元整。甲方将委托丙方作为居间人代收全部转让款，乙方须于本协议书签订之日起三日内向丙方支付以上全部转让款。后由丙方将转让款通过转账的方式支付至甲方账户内，账户如下：</div>
    <div v-else>4、甲方委托丙方将甲方从湖北利道科技投资有限公司工会委员会免费受赠的<span>{{queryList.num}}</span>权数的分红权以人民币<span>{{queryList.price}}</span>元/权数的分红权转让给乙方，乙方受让以上全部权数的分红权须向甲方共计支付人民币<span>{{(queryList.price*queryList.num).toFixed(2)}}</span> 元整。甲方将委托丙方作为居间人代收全部转让款，甲方指定乙方将以上全部转让款转帐的方式支付至以下账户内，账户如下：</div>
    <div v-if="queryList.id">
      <div>用户名：<span>{{dataList.sales_user.union_info.bank.userName}}</span></div>
      <div>账 号：<span>{{dataList.sales_user.union_info.bank.number}}</span></div>
      <div>开户行：<span>{{dataList.sales_user.union_info.bank.bank}}</span></div>
      <div>开户行联行号：<span>{{dataList.sales_user.union_info.bank.Unionpay}}</span></div>
    </div>
    <div v-else>
      <div>用户名：<span>{{tableData.user.union_info.bank.userName}}</span></div>
      <div>账 号：<span>{{tableData.user.union_info.bank.number}}</span></div>
      <div>开户行：<span>{{tableData.user.union_info.bank.bank}}</span></div>
      <div>开户行联行号：<span>{{tableData.user.union_info.bank.Unionpay}}</span></div>
    </div>

    <div>以上账户内收到乙方支付的款项，则视为甲方收到了乙方的转让款。</div>
    <div>5、在甲方收到乙方的全部转让款后，甲方委托丙方与湖北利道科技投资有限公司工会委员会就此前甲方受赠获得的湖北利道科技投资有限公司工会委员会的会员分红权转让事宜进行沟通协调，甲方同意湖北利道科技投资有限公司工会委员会有权直接在其此前受赠获得的湖北利道科技投资有限公司工会委员会的会员分红权总权数中直接扣除本次甲方向乙方转让的分红权的权数，由乙方与湖北利道科技投资有限公司工会委员会就甲方转让给乙方的分红权签订《利道工会会员分红权赠予合同书》，乙方受让以上分红权后，分红权相应的权益及法律责任均由乙方享有及承担，甲方概不负责。</div>
    <div class="size_weight">风险提示：甲方向乙方转让的分红权的每权数的价格系由甲、乙双方自主协商确定，故以上价格可能存在波动或以上分红权可能并不存在价值，故甲方在选择出让以上分红权时应充分考虑以上法律风险，由此产生的盈亏由甲方自负。乙方在选择受让以上分红权时应充分考虑以上法律风险，由此产生的盈亏由乙方自负。</div>
    <div class="size_weight">三、甲方的义务</div>
    <div>1、如果居间成功,则由甲方全面履行分红权转让项目的合同内容。甲方因履行该合同而产生的权利和义务,与丙方无关。</div>
    <div>2、如果居间成功,则甲方应按本合同约定,向丙方支付居间报酬。</div>
    <div>3、如果居间成功，则甲方应按本合同约定配合乙方及丙方、湖北利道科技投资有限公司工会委员会办理分红权转让事宜，并有义务重新签订《利道工会会员分红权赠予合同书》。</div>
    <div class="size_weight">四、乙方的义务</div>
    <div>1、如果居间成功,则由乙方全面履行分红权转让项目的合同内容。乙方因履行该合同而产生的权利和义务,与丙方无关。</div>
    <div>2、乙方有义务按本合同约定，向甲方支付分红权转让款。</div>
    <div>3、如果居间成功，则乙方应按本合同约定配合甲方及丙方、湖北利道科技投资有限公司工会委员会办理分红权转让事宜，并有义务签订《利道工会会员分红权赠予合同书》。</div>
    <div class="size_weight">五、丙方的义务</div>
    <div>1、丙方不得以其在居间过程中获取的甲方及湖北利道科技投资有限公司工会委员会的商业秘密而作出不利甲方及湖北利道科技投资有限公司工会委员会的任何行为否则甲方有权拒绝支付乙方的居间报酬。甲方向丙方提供的任何资料属于甲方及湖北利道科技投资有限公司工会委员会的商业秘密,丙方不得为非履行本合约目的而使用。</div>
    <div>2、丙方不得将本合同委托事项进行转委托。</div>
    <div>3、丙方应及时将分红权转让项目的进行情况以及遇到的问题如实向甲方报告。</div>
    <div>4、丙方有权代理甲方执行与本合同相关的一切事项,丙方代表甲方对外实施的民事行为的法律责任均由甲方承担。</div>
    <div>5、在乙方完成尽职调查,确认有意受让分红权转让项目后,丙方应协助甲方与乙方及湖北利道科技投资有限公司工会委员会签订合同。</div>
    <div class="size_weight">六、居间报酬的计算方法、支付时间和支付方式以及转让款支付方式</div>
    <div v-if="queryList.id">
      <div>1、如居间成功,分红权转让项目居间报酬为乙方向甲方支付的分红权总转让款的 <span>{{dataList.sales.tax_rate*100}}</span>% （百分之 {{dataList.sales.tax_rate*100}}），即人民币 <span>{{dataList.sales.priceText}}</span>。</div>
      <div>2、居间成功后,甲方于二日内一次性向丙方支付居间报酬人民币 元（大写：<span>{{dataList.sales.priceText1}}</span> ）整。</div>
    </div>
    <div v-else>
      <div>1、如居间成功,分红权转让项目居间报酬为乙方向甲方支付的分红权总转让款的 <span>{{tableConfig.shares_tax_rate*100}}</span>% （百分之 {{tableConfig.shares_tax_rate*100}}），即人民币 <span>{{tableConfig.priceText}}</span>。</div>
      <div>2、居间成功后,甲方于二日内一次性向丙方支付居间报酬人民币 元（大写：<span>{{tableConfig.priceText1}}</span> ）整。</div>
    </div>
    <div>3、甲方委托乙方通过转帐的方式向丙方支付本协议书第六条第一款及第二款约定的全部居间报酬，在乙方代甲方向丙方支付以上全部居间报酬后，乙方有权在向甲方支付全部转让款时，直接扣除以上代甲方向丙方支付的居间报酬，其后再将余款向甲方进行转账支付。甲方委托乙方向丙方的以下账户支付居间报酬，由此产生的乙方的损失及费用等均由甲方承担。</div>
    <div>用户名：<span>{{tableConfig.shares_sales_payee}}</span></div>
    <div>账号：<span>{{tableConfig.shares_sales_payee}}</span></div>
    <div>开户行：<span>{{tableConfig.shares_sales_payee_address}}</span></div>
    <div>开户行联行号：<span>102521003841</span></div>
    <div class="size_weight">七、居间费用的承担</div>
    <div>本合同所称居间费用是指丙方为完成委托事项实际支出的必要费用,无论丙方是否居间成功。居间费用均由居间人自行负担,与甲方无关。</div>
    <div class="size_weight">八、特别约定</div>
    <div>1、委托期满,如没有居间成功,甲方无需向丙方支付任何费用。</div>
    <div>2、诚信原则:委托期内,如果甲方或乙方就有关分红权转让与湖北利道科技投资有限公司工会委员会订立《利道工会会员分红权赠予合同书》的,甲方必须按本协议约定支付丙方居间报酬。</div>
    <div>3、本协议内容为商业秘密,三方必须严格保密,不得向其它第三方披露。如有违约,守约方可追究违约方的相关赔偿责任,违约方并承担诉讼费、律师费、保全费、公告费、鉴定费、差旅费等相关费用。</div>
    <div>4、委托期限内居间成功的,甲方在居间成功后二日内需向丙方提交此前甲方与湖北利道科技投资有限公司工会委员会签订《利道工会会员分红权赠予合同书》原件。</div>
    <div class="size_weight">九、违约责任</div>
    <div>1、委托期限内,如居间成功,甲方应按本合同约定,向丙方支付居间报酬。</div>
    <div>如果甲方未及时支付,每逾期一天甲方自愿按全国银行间同业拆借中心发布的贷款市场报价利率4倍为标准向丙方支付违约金。</div>
    <div>2、在甲方未向丙方支付居间报酬前，丙方有权拒绝为甲方提供居间服务。</div>
    <div class="size_weight">十、争议的解决</div>
    <div>如甲、乙、丙三方就本协议的履行发生争议,应友好协商解決,协商不成,因本协议引起的或与本协议有关的争议,任何一方均有权向丙方住所地人民法院提起诉讼。</div>
    <div class="size_weight">十一、其他事项</div>
    <div>1、本协议自甲、乙、丙三方签字或盖章之日起生效。</div>
    <div>2、其他未尽事宜依据国家相关法律执行或由甲、乙、丙三方另行签订补充协议,补充协议与本协议具有同等效力。</div>
    <div>3、本合同一式肆份,甲、乙、丙三方各持壹份,湖北利道科技投资有限公司工会委员会留存壹份，每份均具有同等法律效力。</div>
    <div>(以下无正文)</div>
    <div class="namebox" style="margin-top:0">
      <div>甲方（签字）:</div><img class="nameImg" v-if="(dataList.sales_info.signature||img1)" :src="dataList.sales_info.signature||img1" />
    </div>
    <div class="namebox">
      <div>乙方（签字）：</div><img  class="nameImg"  v-if="dataList.trade_info.signature||img" :src="dataList.trade_info.signature||img" />
    </div>
    <div class="namebox">丙方（盖章）：武汉鸿运荣生态科技有限公司<img src="../../assets/icon10.png" /></div>
    <!-- <div>代表人（签名）：</div> -->
    <div class="namebox" style="text-align: right">签订日期： 年 月 日</div>

  </div>
</template>
<script>
import { dealDetails_api, getConfig_api, putOrder_api } from "@/api/deal";
export default {
  data() {
    return {
      tableData: {
        user: {
          union_info: {}
        }
      },
      tableConfig: {},
      img: "",
      img1: "",
      queryList: {},
      dataList:{
        trade_info:{
          signature:''
        },
        sales_info:{
          union_info:{
            bank:{}
          },
          signature:''
        }
      },
      dataTime: "",
      isShow:false
    };
  },
  created() {
    this.queryList = this.$route.query;
    document.title = "居间协议";
    if(this.queryList.id){
      this.getDetails(this.queryList.id);
    }
    if(this.queryList.idx){
      this.getDetails(this.queryList.idx);
    }
    this.getHome();
    this.getConfig();

    if (localStorage.getItem("signature")) {
      this.img = localStorage.getItem("signature");
    }
    if (localStorage.getItem("signature1")) {
      this.img1 = localStorage.getItem("signature1");
    }
    this.dataTime = new Date().getFullYear();
    console.log(this.dataList,"222");
  },
  methods: {
    async getDetails(id) {
      // this.dataList = {}
      await putOrder_api(id).then(res => {
        if (res.code == 0) {
        this.dataList = res.data;
        this.isShow = true;
        this.dataList.sales.price =
          res.data.sales.shares_amount *
          ((res.data.sales.tax_rate * 100) / 100).toFixed(2);
        this.dataList.sales.priceText = this.moneyToCapital(
          this.dataList.sales.price
        );
        this.dataList.sales.priceText1 = this.moneyToCapital(
          res.data.sales.shares_amount * ((res.data.sales.tax_rate * 100) / 100)
        );
        this.dataList.sales.rateText = this.moneyToCapital(
          res.data.sales.tax_rate * 100
        );
        if(!this.dataList.trade_info){
          this.dataList.trade_info = {}
        }
        if(!this.dataList.sales_info){
          this.dataList.sales_info = {}
        }
        
        }
      })
        
    },
    moneyToCapital(n) {
      if (n == 0) {
        return "零";
      }
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return "";
      var unit = "千百拾亿千百拾万千百拾元角分",
        str = "";
      n += "00";
      var p = n.indexOf(".");
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++)
        str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
      return str
        .replace(/零(千|百|拾|角)/g, "零")
        .replace(/(零)+/g, "零")
        .replace(/零(万|亿|元)/g, "$1")
        .replace(/(亿)万|壹(拾)/g, "$1$2")
        .replace(/^元零?|零分/g, "")
        .replace(/元$/g, "元整");
    },
    getHome() {
      dealDetails_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.tableData = res.data;
          console.log(this.tableData,this.dataList),"ceshi ";
        }
      });
    },
    getConfig() {
      let data = {
        config: [
          "shares_sales_payee",
          "shares_sales_payee_account",
          "shares_sales_payee_address",
          "shares_commission_rate",
          "shares_tax_rate"
        ]
      };
      getConfig_api(data).then(res => {
        console.log(res.data);
        this.tableConfig = res.data;
        console.log(
          this.queryList.price *
            this.queryList.num *
            ((this.tableConfig.shares_tax_rate * 100) / 100),
          111
        );
        this.tableConfig.priceText = this.moneyToCapital(
          this.queryList.price *
            this.queryList.num *
            ((this.tableConfig.shares_tax_rate * 100) / 100)
        );
        this.tableConfig.priceText1 = this.moneyToCapital(
          this.queryList.price *
            this.queryList.num *
            ((this.tableConfig.shares_tax_rate * 100) / 100)
        );
      });
    }
  }
};
</script>
<style scoped>
.content {
  font-size: 25px;
  padding: 20px 30px;
  box-sizing: border-box;
}
.size_weight {
  font-weight: bold;
  margin-top: 20px;
}
.size_title {
  font-size: 35px;
  text-align: center;
  margin: 30px;
}
div > span {
  display: inline-block;
  border-bottom: 1px solid #000;
  height: 30px;
  /* padding-left: 20px; */
  box-sizing: border-box;
  text-align: center;
}
div {
  margin: 20px 0;
  line-height: 35px;
}
.namebox {
  margin: 180px 0;
  position: relative;
  display: flex;
}
.namebox img {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -130px;
  left: 200px;
}
.namebox .nameImg {
  width: 100px;
  /* height: 50px; */
  top: -100px;
  left: 300px;
  transform: rotate(-90deg);
}
</style>